import React from 'react';
import { Calendar, Globe, DollarSign, TrendingUp } from 'lucide-react';

const Overview = ({ protocol }) => {
  return (
    <div>
      <table className="retro-table">
        <tbody>
          <tr>
            <td><Calendar className="icon" /> Launch Date:</td>
            <td className="text-white">{protocol.launchDate}</td>
          </tr>
          <tr>
            <td><Globe className="icon" /> Website:</td>
            <td>
              <a href={protocol.website} target="_blank" rel="noopener noreferrer" className="text-cyan">
                {protocol.website}
              </a>
            </td>
          </tr>
          <tr>
            <td><DollarSign className="icon" /> TVL:</td>
            <td className="text-yellow">${protocol.tvl.toLocaleString()}</td>
          </tr>
          <tr>
            <td><TrendingUp className="icon" /> Market Cap:</td>
            <td className="text-magenta">${protocol.marketCap.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Overview;