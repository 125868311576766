import React from 'react';

const TokenMetrics = ({ metrics }) => {
  return (
    <div className="retro-card">
      <h2>On-Chain & Token Metrics</h2>
      <h3>On-Chain Metrics</h3>
      <table className="retro-table">
        <tbody>
          <tr>
            <td>Daily Transaction Volume:</td>
            <td>${metrics.onChain.dailyVolume.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Weekly Active Users:</td>
            <td>{metrics.onChain.weeklyActiveUsers.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Monthly Growth Rate:</td>
            <td>{(metrics.onChain.monthlyGrowthRate * 100).toFixed(2)}%</td>
          </tr>
        </tbody>
      </table>
      <h3>Token Information</h3>
      <table className="retro-table">
        <tbody>
          <tr>
            <td>Total Supply:</td>
            <td>{metrics.token.totalSupply.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Circulating Supply:</td>
            <td>{metrics.token.circulatingSupply.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Holder Concentration:</td>
            <td>{metrics.token.holderConcentration}</td>
          </tr>
        </tbody>
      </table>
      <p>Token Utility: {metrics.token.utility ? 'Yes' : 'No'}</p>
    </div>
  );
};

export default TokenMetrics;