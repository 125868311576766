import React from 'react';
import { Shield, FileText, GitBranch, Users } from 'lucide-react';

const SecurityControl = ({ security }) => {
  return (
    <div>
      <div className="retro-section">
        <h3 className="text-cyan"><Shield className="icon" /> Security Audits</h3>
        <p>Status: <span className="text-yellow">{security.auditStatus}</span></p>
        <ul className="retro-list">
          {security.auditReports.map((report, index) => (
            <li key={index}>
              <FileText className="icon" />
              <a href={report.link} target="_blank" rel="noopener noreferrer" className="text-magenta">
                Audit Report {index + 1}
              </a>
            </li>
          ))}
        </ul>
      </div>
      
      <div className="retro-section">
        <h3 className="text-cyan"><GitBranch className="icon" /> Contract Upgradeability</h3>
        <ul className="retro-list">
          <li><span className="text-white">Type:</span> <span className="text-yellow">{security.contractType}</span></li>
          <li><span className="text-white">Last Upgrade:</span> <span className="text-yellow">{security.lastUpgrade}</span></li>
          <li><span className="text-white">Upgrade Governance:</span> <span className="text-yellow">{security.upgradeGovernance}</span></li>
        </ul>
      </div>
      
      <div className="retro-section">
        <h3 className="text-cyan"><Users className="icon" /> Multisig Wallet</h3>
        <ul className="retro-list">
          <li><span className="text-white">Structure:</span> <span className="text-yellow">{security.multisig.structure}</span></li>
          <li><span className="text-white">Role:</span> <span className="text-yellow">{security.multisig.role}</span></li>
        </ul>
        <p className="text-white">Signers:</p>
        <ul className="retro-list">
          {security.multisig.signers.map((signer, index) => (
            <li key={index} className="text-magenta">{signer}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SecurityControl;