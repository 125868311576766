import React from 'react';
import { useParams } from 'react-router-dom';
import Overview from '../components/protocol/Overview';
import SecurityControl from '../components/protocol/SecurityControl';
import Team from '../components/protocol/Team';
import Governance from '../components/protocol/Governance';
import TokenMetrics from '../components/protocol/TokenMetrics';
import { getProtocolData } from '../utils/api';
import { BarChart2, Shield, Users, Vote, Banknote } from 'lucide-react';

const ProtocolPage = () => {
  const { id } = useParams();
  const protocolData = getProtocolData(id);

  return (
    <div className="container">
      <h1 className="retro-header text-cyan">Protocol: {protocolData.name}</h1>
      
      <div className="retro-card">
        <h2 className="text-magenta"><BarChart2 className="icon" /> Overview</h2>
        <Overview protocol={protocolData.overview} />
      </div>
      
      <div className="retro-flex-container">
        <div className="retro-flex-item retro-card">
          <h2 className="text-yellow"><Shield className="icon" /> Security & Control</h2>
          <SecurityControl security={protocolData.security} />
        </div>
        
        <div className="retro-flex-item retro-card">
          <h2 className="text-cyan"><Users className="icon" /> Team</h2>
          <Team team={protocolData.team} />
        </div>
      </div>
      
      <div className="retro-flex-container">
        <div className="retro-flex-item retro-card">
          <h2 className="text-magenta"><Vote className="icon" /> Governance</h2>
          <Governance governance={protocolData.governance} />
        </div>
        
        <div className="retro-flex-item retro-card">
          <h2 className="text-yellow"><Banknote className="icon" /> Token Metrics</h2>
          <TokenMetrics metrics={protocolData.metrics} />
        </div>
      </div>
    </div>
  );
};

export default ProtocolPage;