import React from 'react';

const Team = ({ team }) => {
  return (
    <div className="retro-card">
      <h2>Team</h2>
      {team.members.map((member, index) => (
        <div key={index} className="retro-card">
          <h3>{member.name}</h3>
          <p>Role: {member.role}</p>
          <p>Past Projects: {member.pastProjects.join(', ')}</p>
          <p>
            Profiles:
            {Object.entries(member.profiles).map(([platform, url], i) => (
              <span key={i}>
                {' '}
                <a href={url} target="_blank" rel="noopener noreferrer">{platform}</a>
              </span>
            ))}
          </p>
        </div>
      ))}
      <p>Team Involvement: {team.involvement}</p>
      <p>Transparency Score: {team.transparencyScore}/10</p>
    </div>
  );
};

export default Team;