import React, { useState, useEffect } from 'react';
import matter from 'gray-matter';
import { AlertTriangle, Trash2, Scale, ExternalLink } from 'lucide-react';
import '../styles/components/BlotterPage.css';

const BlotterPage = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventFiles = [
          '2024-09-01-defi-protocol-a-exploit.md',
          '2024-09-05-yield-farm-b-rug-pull.md',
          '2024-09-10-exchange-c-regulatory-action.md',
          '2024-09-15-bridge-d-hack.md',
          '2024-09-20-nft-project-e-insider-trading.md'
        ];

        const fetchedEvents = await Promise.all(
          eventFiles.map(async (filename) => {
            const response = await fetch(`/data/blotter-events/${filename}`);
            const text = await response.text();
            const { data, content } = matter(text);
            return {
              ...data,
              content,
              id: filename.replace('.md', ''),
            };
          })
        );

        setEvents(fetchedEvents.sort((a, b) => new Date(b.date) - new Date(a.date)));
      } catch (error) {
        console.error('Error fetching events:', error);
        setError('Failed to load events. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const getEventTypeInfo = (type) => {
    switch(type) {
      case 'hack':
        return { icon: <AlertTriangle size={18} className="icon text-yellow" />, label: 'Hack', className: 'text-yellow' };
      case 'rug':
        return { icon: <Trash2 size={18} className="icon text-magenta" />, label: 'Rug Pull', className: 'text-magenta' };
      case 'enforcement':
        return { icon: <Scale size={18} className="icon text-cyan" />, label: 'Enforcement', className: 'text-cyan' };
      default:
        return { icon: null, label: 'Unknown', className: 'text-white' };
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (isLoading) return <div className="loading">Loading events...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="container">
      <h1 className="retro-header text-cyan">Blotter</h1>
      <div className="retro-card blotter-container">
        <div className="blotter-table-container">
          <table className="blotter-table">
            <thead className="blotter-table-header">
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Protocol</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Source</th>
              </tr>
            </thead>
            <tbody>
              {events.map((item) => {
                const eventType = getEventTypeInfo(item.type);
                return (
                  <tr key={item.id} className="blotter-item">
                    <td data-label="Type">
                      <div className="event-type">
                        {eventType.icon}
                        <span className={`event-type-label ${eventType.className}`}>{eventType.label}</span>
                      </div>
                    </td>
                    <td data-label="Date" className="text-white">{formatDate(item.date)}</td>
                    <td data-label="Protocol" className="text-yellow">{item.protocol}</td>
                    <td data-label="Description" className="text-magenta">{item.description}</td>
                    <td data-label="Amount" className="text-cyan">{item.amount ? `$${parseInt(item.amount).toLocaleString()}` : 'N/A'}</td>
                    <td data-label="Source">
                      <a href={item.source} target="_blank" rel="noopener noreferrer" className="source-link">
                        <ExternalLink size={16} />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BlotterPage;