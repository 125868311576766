import React from 'react';

const Governance = ({ governance }) => {
  return (
    <div className="retro-card">
      <h2>Governance Structure</h2>
      <h3>Governance Model</h3>
      <p>Type: {governance.model.type}</p>
      <p>Voting Mechanism: {governance.model.votingMechanism}</p>
      <p>Decentralization Score: {governance.model.decentralizationScore}/10</p>
      <h3>Recent Governance Proposals</h3>
      <table className="retro-table">
        <thead>
          <tr>
            <th>Proposal</th>
            <th>Status</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {governance.recentProposals.map((proposal, index) => (
            <tr key={index}>
              <td><a href={proposal.link} target="_blank" rel="noopener noreferrer">{proposal.title}</a></td>
              <td>{proposal.status}</td>
              <td>{proposal.result}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Governance;