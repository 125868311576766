import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProtocolPage from './pages/ProtocolPage';
import BlotterPage from './pages/BlotterPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="container">
        <header className="retro-header">
          <h1>DefiDeputy</h1>
        </header>
        <nav className="retro-nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/protocol/example">Protocol Example</Link></li>
            <li><Link to="/blotter">Blotter</Link></li>
          </ul>
        </nav>
        <main>
          <Routes>
            <Route path="/protocol/:id" element={<ProtocolPage />} />
            <Route path="/blotter" element={<BlotterPage />} />
            <Route path="/" element={<h2>Welcome to DefiDeputy</h2>} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;