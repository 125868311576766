// src/utils/api.js

const mockProtocolData = {
  name: "Example Protocol",
  overview: {
    name: "Example Protocol",
    type: "DEX",
    launchDate: "2023-01-01",
    website: "https://example.com",
    tvl: 1000000000,
    marketCap: 500000000
  },
  security: {
    auditStatus: "Completed",
    auditReports: [{ link: "https://example.com/audit1" }],
    contractType: "Upgradeable",
    lastUpgrade: "2023-06-01",
    upgradeGovernance: "DAO",
    multisig: {
      structure: "5-of-7",
      role: "Treasury management",
      signers: ["Alice", "Bob", "Charlie", "David", "Eve"]
    }
  },
  team: {
    members: [
      {
        name: "Alice",
        role: "CEO",
        pastProjects: ["Project X", "Project Y"],
        profiles: { 
          Twitter: "https://twitter.com/alice",
          GitHub: "https://github.com/alice"
        }
      }
    ],
    involvement: "Full-time",
    transparencyScore: 8
  },
  governance: {
    model: {
      type: "DAO",
      votingMechanism: "Token-weighted",
      decentralizationScore: 7
    },
    recentProposals: [
      {
        title: "Proposal 1",
        status: "Passed",
        result: "Implemented",
        link: "https://example.com/proposal1"
      }
    ]
  },
  metrics: {
    onChain: {
      dailyVolume: 10000000,
      weeklyActiveUsers: 50000,
      monthlyGrowthRate: 0.05
    },
    token: {
      totalSupply: 1000000000,
      circulatingSupply: 750000000,
      holderConcentration: "Top 10 holders own 30%",
      utility: true
    }
  }
};

export const getProtocolData = (id) => {
  // In a real application, you would fetch data based on the id
  // For now, we'll just return the mock data
  console.log(`Fetching data for protocol with id: ${id}`);
  return mockProtocolData;
};